export default {
    shoe_message(msg_info) {

        var ringUrl = "/sound/5c8950e32ec0017458.mp3"
        var audio = new Audio(ringUrl);
        audio.play();

        $ = jQuery;
        return $.growl.notice({
            title: "您有新訊息",
            message: msg_info
        });
    }

}
