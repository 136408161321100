<template>
  <label>
    <span>{{ label }}</span>
    <textarea
      :value="value"
      :placeholder="placeholder"
      :rows="rows"
      @input="onChange"
    ></textarea>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 3,
    },
    helpText: {
      type: String,
      default: '',
    },
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>
